import React from 'react';
import UptradeContext from './context/UptradeContext';

export default function withUptradeContext(Component) {
  return function WrapperComponent(props) {
    return (
      <UptradeContext.Consumer>
        {(state) => <Component {...props} uptradeContext={state} />}
      </UptradeContext.Consumer>
    );
  };
}
