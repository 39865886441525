import React from 'react';
import Layout from '../../layouts/index';

import PaymentSuccess from '../../components/TilliUptrade/PaymentSuccess';

const PayInSuccessPage = () => (
  <Layout routeSlug="PayInSuccessPage">
    <PaymentSuccess />
  </Layout>
);

export default PayInSuccessPage;
